.btn-show-all{
    margin-left: 10px;
}


.lead-details-column{
    width: 100%;
}

.discovery-title-row{
    display: flex;
    align-items: center;
}

.discovery-container{
    background-color: white;
    border-radius: 8px;
    padding: 10px 10px;
    margin-bottom: 20px;
}

.discovery-table{
    margin: 20px 0px;
}

.discovery-details-container i{
    color: #4A9DEC;
}

.buttons-line-discovery{
    display: flex;
    justify-content: space-between;
}