.profile-table-container {
  width: 100%;
  margin-bottom: 30px;
}

.expand-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cell-header {
  width: 30%;
  background-color: rgb(236, 236, 236);
  font-size: small;
}

.paid-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.paid-line i {
  color: rgb(188, 4, 4);
  font-size: 25px;
}

.table-pack-details {
  margin-right: 20px;
}

.client-area-link {
  color: blue;
}

.client-area-link:hover {
  cursor: pointer;
  text-decoration: underline;
}

.tooltip-client-area-link {
  margin-left: 20px;
  color: gray;
  font-weight: normal;
  text-decoration: none !important;
}

.tooltip-client-area-link:hover {
  text-decoration: none !important;
}

.link-lead-converted a {
  text-decoration: none;
}

.insta-cell:hover {
  color: blue;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .profile-table-container {
    width: 100%;
  }
}
