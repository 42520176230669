.user-details-main-container {
  background-color: rgb(239, 239, 239);
  max-height: 100vh;
  width: 100%;
  overflow: auto;
}

.user-details-first-column {
  width: 60%;
  margin-right: 20px;
}

.user-details-internal-container {
  margin: 10px;
}

.user-details-first-row {
  display: flex;
  align-items: start;
  justify-content: space-between;
}

.user-details-second-row {
  margin-top: 20px;
  display: flex;
  align-items: start;
  justify-content: space-between;
}

.column-tables {
  width: 100%;
}

.converted-section-user {
  background-color: white;
  width: auto;
  padding: 10px 10px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.converted-section-user span {
  color: #0155f1;
}

.converted-section-user span:hover {
  cursor: pointer;
  color: red;
}

/* Styles for tablet and phone screens */
@media screen and (max-width: 768px) {
  .user-details-first-row,
  .user-details-second-row {
    flex-direction: column;
  }

  .user-details-first-column {
    width: 100%;
  }
}
