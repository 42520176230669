/* UserTable.css */
/* Table header styles */

.table-container {
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.table-users {
  border-radius: 8px;
  padding: 3px;
}

.table-header {
  background-color: #dcdbdb; /* Gray background color */
  padding: 8px; /* Padding for header cells */
  text-align: left;
  border: 1px solid #ccc;
  /* Align header text to the left */
}

/* Table data (cell) styles */
.table-cell {
  border: 1px solid #ccc; /* Gray border around cells */
  padding: 8px; /* Padding for data cells */
  text-align: left;
  background-color: white; /* Align cell text to the left */
}

/* Add spacing between columns */
.table-cell + .table-cell {
  border-left: 1px solid #ccc; /* Gray line between columns */
}

/* Style for the user link */
.user-link {
  text-decoration: none;
  color: #007bff; /* Blue color for the link */
}

.user-link:hover {
  text-decoration: none; /* Underline on hover */
  color: #0056b3; /* Darker blue color on hover */
}

.search-user {
  height: auto;
  margin-bottom: 10px;
  display: flex;
  line-height: 28px;
  align-items: center;
  position: relative;
}

.search-bar {
  width: 80%;
  height: 40px;
  line-height: 28px;
  padding: 0 1rem;
  padding-left: 2.5rem;
  border: 2px solid transparent;
  border-radius: 8px;
  outline: none;
  background-color: #ffffff;
  color: #0d0c22;
  transition: 0.3s ease;
}

.search-bar:focus,
input:hover {
  outline: none;
  border-color: #367af6;
  background-color: #fff;
  box-shadow: 0 0 0 4px rgb(54 122 246 / 10%);
}

.search-user i {
  position: absolute;
  left: 1rem;
  fill: #9e9ea7;
  width: 1rem;
  height: 1rem;
}

.pagination {
  margin-top: 20px;
}

.btn-pagination {
  padding: 5px 10px;
  border: 1px solid rgb(197, 197, 197);
}

.btn-pagination-active {
  background-color: #007bff;
  color: white;
}

.name-header {
  display: flex;
  justify-content: space-between;
}

