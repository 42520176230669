/* Reset some default styles */
body,
html {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', sans-serif;
  height: 100%;
  overflow: hidden;
  /* Hide horizontal scrollbar */
}

.active-link {
  color: green !important;
  /* Change to your desired text color */
}

/* Overall container for the layout */
.container {

  display: flex;
  height: 100%;
  overflow: hidden;
  /* Hide horizontal scrollbar */
}

/* Sidebar and portal styles */


.portal {
  flex-grow: 1;
  /* Allow the portal to take up the remaining height */
  padding: 20px;
  overflow-y: auto;
  /* Add scrollbar if content exceeds portal height */
  background-color: #f0f0f0;
  /* Example background color for the portal */
}

/* Example dashboard card styling */
.card {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Add additional styling as needed for your project */

/* Add styles to center the login container */
/* Add styles to center the login container */
.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* Center vertically on the viewport */
}

.login-container {
  width: 300px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #fff;
  text-align: center;
  /* Center text horizontally */
}

/* Add a style for the active link in the sidebar */
/* Style for the active link in the sidebar */



/* Add styles for the app container */
.app-container {
  display: flex;
}

.title-container-expandable {
  background-color: white;
  border-radius: 8px;
  padding: 0px 10px;
  height: auto;
  margin-bottom: 10px;
}

.title-container-expandable h3 {
  margin: 15px 0px;
}

/* Table styles */
table {
  width: 100%;
  border-collapse: separate;
  border: 1px gray;
  background: rgb(236, 236, 236);
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

/* Header row styles */
th {
  background-color: #ccc;
  /* Gray background color */
  padding: 8px;
  /* Padding for header cells */
  text-align: left;
  /* Align header text to the left */
  font-weight: 600;
  letter-spacing: normal;
}

/* Table data (cell) styles */
td {
  border: 1px solid #ccc;
  /* Gray border around cells */
  padding: 8px;
  /* Padding for data cells */
  text-align: left;
  /* Align cell text to the left */
}

/* Add spacing between columns */
td+td {
  border-left: 1px solid #ccc;
  /* Gray line between columns */
}


.row-tables-flex {
  display: flex;
  align-items: start;
  justify-content: space-between;
}

/* .control-bar{
  margin-bottom: 20px;
  background-color: white;
  border-radius: 14px;
  padding: 5px;
  box-shadow: 15px 15px 30px #bebebe,
             -15px -15px 30px #ffffff;
} */

.row-btns {
  display: flex;
  align-items: center;
}

.second-btn-additional {
  margin-left: 10px;
}


/* CSS */
.button-10 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6px 14px;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', sans-serif;
  border-radius: 6px;
  border: none;

  color: #fff;
  background: linear-gradient(180deg, #4B91F7 0%, #367AF6 100%);
  background-origin: border-box;
  box-shadow: 0px 0.5px 1.5px rgba(54, 122, 246, 0.25), inset 0px 0.8px 0px -0.25px rgba(255, 255, 255, 0.2);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-10:focus {
  box-shadow: inset 0px 0.8px 0px -0.25px rgba(255, 255, 255, 0.2), 0px 0.5px 1.5px rgba(54, 122, 246, 0.25), 0px 0px 0px 3.5px rgba(58, 108, 217, 0.5);
  outline: 0;
}

.button-10:hover,
.button-12:hover {
  cursor: pointer;
}



/* CSS */
.button-12 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6px 14px;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', sans-serif;
  border-radius: 6px;
  border: none;

  background: #6E6D70;
  box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.1), inset 0px 0.5px 0.5px rgba(255, 255, 255, 0.5), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.12);
  color: #DFDEDF;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-12:focus {
  box-shadow: inset 0px 0.8px 0px -0.25px rgba(255, 255, 255, 0.2), 0px 0.5px 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 3.5px rgba(58, 108, 217, 0.5);
  outline: 0;
}



.button-13 {
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  cursor: pointer;
  width: auto;
  height: auto;
  background-image: linear-gradient(to top, #D8D9DB 0%, #fff 80%, #FDFDFD 100%);
  border-radius: 10px;
  border: 1px solid #8F9092;
  transition: all 0.2s ease;
  font-weight: 600;
  color: #606060;
  text-shadow: 0 1px #fff;
}

.button-13 p {
  margin: 5px;
}


.button-14 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6px 14px;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', sans-serif;
  border-radius: 6px;
  border: none;

  background: linear-gradient(45deg, #9E2B25, #5F0A0A);
  /* Dark red gradient */
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3),
    /* Soft shadow */
    inset 0px 0.5px 1px rgba(255, 255, 255, 0.5),
    /* Inner highlight */
    0px 0px 0px 0.5px rgba(0, 0, 0, 0.12);
  /* Border */
  color: #DFDEDF;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}







.btn-pagination {
  /* display: flex;
  align-items: center;
  justify-content: center; */
  outline: none;
  cursor: pointer;
  background-image: linear-gradient(to top, #D8D9DB 0%, #fff 80%, #FDFDFD 100%);
  border-radius: 30px;
  border: 1px solid #8F9092;
  transition: all 0.2s ease;

  font-size: 14px;
  font-weight: 600;
  color: #606060;
  text-shadow: 0 1px #fff;
  margin-left: 5px;
}

.btn-pagination:hover {
  box-shadow: 0 4px 3px 1px #FCFCFC, 0 6px 8px #D6D7D9, 0 -4px 4px #CECFD1, 0 -6px 4px #FEFEFE, inset 0 0 3px 3px #CECFD1;
}



.btn-pagination:focus {
  box-shadow: 0 4px 3px 1px #FCFCFC, 0 6px 8px #D6D7D9, 0 -4px 4px #CECFD1, 0 -6px 4px #FEFEFE, inset 0 0 5px 3px #999, inset 0 0 30px #aaa;
}

.btn-pagination-active {


  box-shadow: 0 4px 3px 1px #FCFCFC, 0 6px 8px #D6D7D9, 0 -4px 4px #CECFD1, 0 -6px 4px #FEFEFE, inset 0 0 5px 3px #999, inset 0 0 30px #aaa;

}


.cell-header {
  width: 40%;
  background-color: rgb(236, 236, 236);
  font-size: small;
}


input {
  border: 2px solid transparent;
  width: 80%;
  height: 2.5em;
  padding-left: 0.8em;
  outline: none;
  overflow: hidden;
  background-color: #F3F3F3;
  border-radius: 10px;
  transition: all 0.5s;
  /* margin-bottom: 20px; */
}



input:hover,
input:focus {
  border: 2px solid #4A9DEC;
  box-shadow: 0px 0px 0px 7px rgb(74, 157, 236, 20%);
  background-color: white;
}


@media screen and (max-width: 768px) {
  .row-tables-flex {
    flex-direction: column;
  }
}