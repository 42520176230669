.new-supplement-container input {
  width: 90%;
}

.alert-div-use-supp {
  padding: 10px;
  border-radius: 10px;
  border: 1px solid red;
  display: flex;
}

.alert-div-use-supp i {
  font-size: 30px;
  color: red;
}
