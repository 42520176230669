.cell-delete {
  display: flex;
  text-align: end;
  justify-content: space-between;
}

.img-container-supp {
  width: 10%;
}

.supplement-title {
  width: 50%;
}
.btn-edit-supp {
  margin-bottom: 20px;
}

.supplement-details-btn-line {
  display: flex;
  margin: 0px 20px;
}

.btn-delete-supplement {
  margin-left: 15px;
}

.use-supplement-icon-true {
  color: green;
}

.use-supplement-icon-false {
  color: red;
}

.link-supplement-details {
  color: #005fb8;
  font-weight: 500;
}

.link-supplement-details:hover {
  color: red;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .supplement-details-container {
    width: 100%;
  }
}
