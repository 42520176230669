.intake-form-container {
  background-color: white;
  border-radius: 8px;
  padding: 20px 10px;
}

.intake-form-item {
  margin-bottom: 20px;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
}

.intake-form-title {
  margin-bottom: 5px;
  color: #333;
  font-size: 18px;
}

.intake-form-response {
  font-size: 16px;
  color: #666;
}

.intake-not-submitted-container {
  background-color: white;
  padding: 10px;
  display: flex;
  align-items: center;
  border-radius: 8px;
}

.intake-not-submitted-container i {
  color: red;
  margin-right: 5px;
}

.intake-completed-note {
  margin: 5px;
}

.intake-completed-note i {
  margin-right: 5px;
}

@media screen and (max-width: 768px) {
  .notes-table {
    margin-bottom: 30px;
  }
}
