.sidebar {
  width: 250px !important;
  background-color: #333;
  color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  /* max-height: 100vh; */
  overflow: auto;
  /* Set sidebar height to 100% of viewport height */
}

.logo {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.img-logo-sidebar {
  width: 50%;
}

.menu {
  list-style: none;
  padding: 0;
  width: 100%;
}

.menu li {
  margin-bottom: 30px;
  text-align: start;
}

.submenu {
  list-style-type: none;
}

.menu a {
  color: white;
  text-decoration: none;
  transition: color 0.2s;
}

.menu a:hover {
  color: #ff5722;
  /* Change to your desired hover color */
}

.icons-sidebar {
  margin-right: 10px;
}

.sidebar-title-section {
  color: gray;
  margin-bottom: 20px;
}