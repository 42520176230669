/* Calendar.css */



/* Style the calendar container to make it bigger */


.calendar-container {
 width: 50%;
  /* border: 1px solid gray; */
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  align-items: flex-start; /* Align items to the top */
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.bg-gray {
  background-color: rgba(242, 242, 242, 0.519);
}


.appointments-container {

  margin-left: 20px;
}

.add-appointment-button {
  float: right;
  margin-left: 10px; /* Add margin for spacing */
}

.appointment-circle{
  margin-top: 2px;
  height: 3px;
  background-color:green;
  display: flex;
  justify-content: center;
  align-items: center;


}

.dashboard-first-row{
  display: flex;
  justify-content: space-between;
  
}

.day-widget-container{
  
  width: 50%;
  background-color: white;
  padding: 10px;
  margin-left: 20px;
}



@media screen and (max-width: 768px) {
  .dashboard-first-row {
      flex-direction: column;
  }

  .day-widget-container{
    width: 90%;
    margin: 0px;
  }

  .calendar-container{
    width: 90%;
    margin-bottom: 20px;
  }
}