.client-mainpage-container{
    width: 60%;
    margin: auto;
}


.consultation-item-client-container{
    background-color: white;
    margin: 10px 0px;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    width: 50%;
}

.consultation-item-client-container h4 , p{
    margin: 0px;
}



.sub-label{
    margin: 5px 0px;
    font-size: small;
    color: gray;
    font-weight: 600;
}

.consultation-btn-container{
    flex-direction: column;
    display: flex;
    align-items: end;
    justify-content: space-between;
}



@media screen and (max-width: 768px) {
    .client-mainpage-container{
        width: 100%;
        padding: 10px;
    }

    .consultation-item-client-container{width: 90%;}
  }