.login-page{
    background-color: rgb(239, 239, 239);
}

.login-container{
    padding: 50px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.login-container h2{
    text-align: center;
}

.login-container input {
    border: 2px solid transparent;
    width: 80%;
    height: 2.5em;
    padding-left: 0.8em;
    outline: none;
    overflow: hidden;
    background-color: #F3F3F3;
    border-radius: 10px;
    transition: all 0.5s;
    margin-bottom: 20px;
  }


  
  .login-container input:hover,
  .login-container input:focus {
    border: 2px solid #4A9DEC;
    box-shadow: 0px 0px 0px 7px rgb(74, 157, 236, 20%);
    background-color: white;
  }
  

  .row-btn-login{
    display: flex;
    justify-content: center;
  }

  .btn-login{
    width: 200px;
    
  }