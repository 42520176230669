.title-table-notes {
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 8px;
    padding: 10px 10px;
    margin-bottom: 20px;
}

.title-table-notes-expanded {
    display: flex;
}

.btn-title-container {
    display: flex;
    align-items: center;
}

.title-container-expandable {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media screen and (max-width: 768px) {
    .title-table-notes{
        margin-top: 20px;
    }
  }

