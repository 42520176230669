



.users-scrollable-container{
    background-color: rgb(239, 239, 239);
    max-height: 100vh;
    width: 100%;
    overflow: auto;   
}

.users-container{
    padding: 20px;
}

.add-client-button{
    margin-left: 10px;

}

