.records-header{
    display: flex;
    align-items: center;
}

.btn-add-record{
    margin-left: 10px;
}

.record-details-container{
    width: 50%;

}

.records-table{
    margin: 20px;
}

.file-record-table{
    display: flex;
    justify-content: space-between;
}


@media screen and (max-width: 768px) {
    .record-details-container {
      width: 90%;
    }
  }