.consultation-details-row {
  display: flex;
  align-items: start;
  justify-content: space-between;
}

.consultation-details-table-container {
  width: 48%;
}

.consultation-details-table {
  background-color: white;
}

.documents-available-table {
  background-color: white;
}

.questionnaire-container {
  margin-top: 20px;
  width: 100%;
}

.btn-questionnaire {
  margin-left: 20px;
}

.form-consultation-table {
  background-color: white;
}

.form-consultation {
  padding: 20px;
}

.form-group-consultation {
  margin-bottom: 20px;
  width: 100%;
}

.form-group-consultation label {
  font-weight: 600;
  font-size: large;
}

.form-consultation-subtitle {
  margin: 10px 0px;
  font-size: small;
}

.list-document-container {
  width: 48%;
}

.list-document-title:hover {
  color: red;
  cursor: pointer;
}

.list-document-container i {
  margin-right: 10px;
  color: #367af6;
}

.list-document-title {
  text-decoration: underline;
  color: #367af6;
}

.date-modal {
  width: auto;
}

.date-modal .modal-content {
  margin: 20px;
}

.date-picker-container input {
  border: 2px solid transparent;
  width: 100%;
  height: 2.5em;
  padding-left: 0.8em;
  outline: none;
  overflow: hidden;
  background-color: #f3f3f3;
  border-radius: 10px;
  transition: all 0.5s;
  margin-bottom: 20px;
}

.date-picker-container input:hover,
.date-picker-container input:focus {
  border: 2px solid #4a9dec;
  box-shadow: 0px 0px 0px 7px rgb(74, 157, 236, 20%);
  background-color: white;
}

.select-date-cell {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.supplement-spec-div {
  background-color: white;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 10px;
}

.supplement-details-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.supplement-line-div {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.supplement-line-div input {
  width: auto;
  margin-bottom: 0px;

  margin-left: 10px;
}

.btn-add-supplement {
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.btn-add-supplement i {
  margin-right: 5px;
}

.save-supp-spec-btn {
  justify-content: end;
}

.supp-details-container {
  margin-top: 10px;
}

.supp-detail-line {
  font-size: 12px;
  color: gray;
}

.supp-detail-line span {
  color: black;
}

.supplement-title h4 {
  margin: 0px;
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
}

.supplement-image {
  max-width: 150px;
  max-height: 80px;
  width: auto;
  height: auto;
  object-fit: contain;
}

.title-add-supplement {
  text-align: center;
}

.search-supplement {
  width: 100%;
}

.supplement-modal input {
  width: 90%;
  margin-bottom: 5px;
}

.supplement-list-result {
  width: 90%;
  background-color: #f3f3f3;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.supplement-list-result h5 {
  margin: 0px;
  margin-bottom: 10px;
}

.supplement-element-list {
  margin-bottom: 10px;
}

.supplement-element-list:hover {
  color: #367af6;
  cursor: pointer;
}

.btns-intake-form-line {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.btn-set-completed {
  margin-left: 10px;
}

.btn-previous {
  margin-left: 10px;
}

@media screen and (max-width: 768px) {
  .consultation-details-row {
    flex-direction: column;
  }

  .consultation-details-table-container,
  .list-document-container {
    width: 100%;
  }
}