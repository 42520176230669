/* TopRow.css */

  
  .navbar {
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    align-items: center;
    background-color: white;
  }
  
  .navbar a {
    color: white;
    text-decoration: none;
    font-weight: bold;

  }
  
  .top-row {
    
    color: black;/* Adjust padding as needed */
    align-items: center;
    text-align: end;
  }

  