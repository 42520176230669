

.day-widget-container{
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.day-widget-container ul{
    padding-inline-start: 0;
    
}


.label-card p{
    margin: 0;
    font-size: 12px;
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px;
    background-color: #367AF6;
    color: white;
    border-top-right-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
    
}


.consultation-line-card {

    --border-radius: 0.75rem;
    --primary-color: #367AF6;
    --secondary-color: #3c3852;
    font-family: "Arial";
    padding: 05px 10px;
    cursor: pointer;
    border-radius: var(--border-radius);
    background: #f1f1f3;
    box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 3%);
    position: relative;
    margin-bottom: 20px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

  }
  
  .consultation-line-card > * + * {
    margin-top: 1.1em;
  }
  
  .consultation-line-card .consultation-line-card__content  {
    color: var(--secondary-color);
    font-size: 0.86rem;
  }
  
  .consultation-line-card .consultation-line-card__title {
    padding: 0;
    font-size: 1.3rem;
    font-weight: bold;
  }
  
  .consultation-line-card .consultation-line-card__date {
    color: var(--secondary-color);
    font-size: 0.86rem;
  }
  
  .consultation-line-card .consultation-line-card__arrow {
    position: absolute;
    background: var(--primary-color);
    padding: 0.4rem;
    border-top-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
    bottom: 0;
    right: 0;
    transition: 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .consultation-line-card svg {
    transition: 0.2s;
  }
  

  .consultation-line-card:hover .consultation-line-card__arrow {
    background: #111;
  }
  
  .consultation-line-card:hover .consultation-line-card__arrow svg {
    transform: translateX(3px);
  }