.table-notes {
    margin-bottom: 20px;
}

.notes-table {
 
    width: 100%;
    margin-right: 30px;

}

.notes-table .pagination {
    margin-bottom: 20px;
}

.notes-table-column-title {
    font-weight: 600;
}

.date-cell {
    font-size: small;
    color: gray;
}

.delete-header {
    width: 10%;
}

.delete-cell {
    text-align: start;
}


@media screen and (max-width: 768px) {
    .notes-table {
        width: 100%;

    }
}