.error-modal-container {
    position: absolute;
    /* top:0%; */
    left: 50%;

}

.error-modal-content {

    align-items: center;
}

.error-modal-btn-div {
    margin-top: 20px;
}




.error-modal {
    width: 300px;
    /* Adjust the width as needed */
    padding: 20px;
    background-color: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    text-align: center;
    top: 50%;
    /* Center vertically */
    left: 50%;
    /* Center horizontally */
    /* transform: translate(-50%, -50%); */
    /* Center both vertically and horizontally */
    z-index: 9999;
    /* Ensure it appears on top of other elements */
}