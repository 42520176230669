.modal-previous-supplements {
    width: 80%;
    height: 60%;
    overflow: auto;
}

.supplement-details-container {
    border: 1px solid rgb(214, 214, 214);
}

.line-btn-previous {
    justify-content: center;
}