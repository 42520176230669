
.client-recommendation-page-container{
    width: 50%;
    margin:auto;
    max-height: 100vh;
    overflow: auto;
}

.recommendation-appbar{
    background-color: rgb(164, 255, 216);
    padding: 20px;
}

.recommendation-section{
    margin-bottom: 20px;
}

.recommendation-title-container{
    background-color: rgb(240, 240, 240);
    padding: 20px 10px;
}




/* .client-recommendation-page-container{
    padding: 10px;
} */

.client-recommendation-page-container h2, h3{
   margin: 0px;
}

.client-recommendation-page-container i{
    margin-right: 10px;
    color:#367AF6;
}

.recommendation-container{
    margin: 10px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    border-radius: 6px;
    padding:15px 10px;
}

.recommendation-response{
    margin-top: 10px;
}

.supplement-img{
    height: 70px;
    width: auto;
    
}

.supplement-name{
    color: #367AF6;
}

.supplement-name:hover{
    color: green;
    cursor: pointer;
}


.supplement-spec{
    margin: 10px 0px;
    display: flex;
    

}

.supp-spec-details{
    margin-left: 20px;
}

@media screen and (max-width: 768px) {
    .client-recommendation-page-container{
        width: 100%;
    }
  }